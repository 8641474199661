import Axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import apiUrls from 'base/api/urls';
import { Meditation, Tags } from 'base/api/types';
import { StandardError } from 'base/api/errors';
import { useDetailsQuery, useListQuery } from 'base/api/hooks';
import { CommonVars, PK } from 'common/types';
import { STATUSES } from 'common/consts';
import { composeUrl } from 'common/utils';

export const useMeditationsList = () => {
  return useListQuery<Meditation>(apiUrls.MEDITATIONS.LIST);
};

export const useMeditationDetails = (pk?: PK) => {
  return useDetailsQuery<Meditation>({
    queryKey: [apiUrls.MEDITATIONS.DETAILS, { pk }],
    pk,
  });
};

interface MeditationCreateVars {
  title: string;
  description: string;
  media_file_pk: PK;
  content_goal_pks: PK[];
}

export const useMeditationCreate = () => {
  const queryClient = useQueryClient();
  return useMutation<Meditation, StandardError, MeditationCreateVars>(
    (data) => Axios.post(apiUrls.MEDITATIONS.LIST, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.MEDITATIONS.LIST);
        queryClient.invalidateQueries(apiUrls.CONTENT.LIST);
      },
    },
  );
};

interface MeditationUpdateVars extends MeditationCreateVars, CommonVars {
  status: STATUSES;
}

export const useMeditationUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<Meditation, StandardError, MeditationUpdateVars>(
    ({ pk, ...data }) =>
      Axios.patch(composeUrl(apiUrls.MEDITATIONS.DETAILS, { params: { pk } }), data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(apiUrls.MEDITATIONS.LIST);
        queryClient.setQueryData([apiUrls.MEDITATIONS.DETAILS, { pk: data.pk }], data);
      },
    },
  );
};

export const useMeditationsTags = (pk?: PK) => {
  return useDetailsQuery<Tags>({ pk, queryKey: [apiUrls.MEDITATIONS.TAGS, { pk }] });
};

interface MeditationsTagsUpdateVars extends CommonVars {
  payload: Tags;
}

export const useMeditationsTagsUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, StandardError, MeditationsTagsUpdateVars>(
    ({ pk, ...payload }) =>
      Axios.put(composeUrl(apiUrls.MEDITATIONS.TAGS, { params: { pk } }), payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiUrls.MEDITATIONS.TAGS);
      },
    },
  );
};
