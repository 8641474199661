import * as Yup from 'yup';

import { MediaObject } from 'base/api/types';
import { combineSchemas } from 'common/utils';
import { Fields, PK, SelectOption, StatusOption } from 'common/types';

export type PodcastFields = {
  title: string;
  description: string;
  status: StatusOption[];
  media_file_pk: MediaObject[];
  content_goals: SelectOption<PK>[];
};

export const fields: Fields<PodcastFields> = {
  title: {
    label: 'Title',
    name: 'title',
    schema: Yup.string().required().max(55),
  },
  description: {
    label: 'Description',
    name: 'description',
    schema: Yup.string().required().max(500),
  },
  status: {
    label: 'Status',
    name: 'status',
    schema: Yup.array().required(),
  },
  media_file_pk: {
    label: 'Video',
    name: 'media_file_pk',
    schema: Yup.array().required(),
  },
  content_goals: {
    label: 'Categories',
    name: 'content_goals',
    schema: Yup.array(),
  },
};

export const validationSchema = Yup.object().shape<PodcastFields>(combineSchemas(fields));

export type PodcastTagsFields = {
  amount: number;
  amount_unit: SelectOption<string>;
  category: SelectOption<string>[];
  habit: SelectOption<string>[];
  subcategory: SelectOption<string>[];
  term: SelectOption<string>;
};

export const tagsFields: Fields<PodcastTagsFields> = {
  amount: {
    label: 'Amount',
    name: 'amount',
    schema: Yup.number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .test('Is positive?', 'Amount must be greater than 0.', (value) => {
        return typeof value === 'object' || (typeof value === 'number' && value > 0);
      }),
  },
  amount_unit: {
    label: 'Amount unit',
    name: 'amount_unit',
    schema: Yup.array()
      .nullable()
      .when('amount', {
        is: (amount) => {
          return typeof amount === 'number';
        },
        then: Yup.array()
          .nullable()
          .required('Amount and Unit fields must be set together'),
      }),
  },
  category: {
    label: 'Category',
    name: 'category',
    schema: Yup.array().required('Category is a required field'),
  },

  habit: {
    label: 'Habit',
    name: 'habit',
    schema: Yup.array().required('Habit is a required field'),
  },

  subcategory: {
    label: 'Subcategory',
    name: 'subcategory',
    schema: Yup.array().required('Subcategory is a required field'),
  },
  term: {
    label: 'Term',
    name: 'term',
    schema: Yup.array().nullable(),
  },
};

export const podcastTagsValidationSchema = Yup.object().shape<PodcastTagsFields>(
  combineSchemas(tagsFields),
);
